.coming-soon-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #0f1b33 0%, #1c2c4c 100%);
  padding: 20px;
}

.coming-soon-content {
  padding: 3rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px 0 rgba(15, 27, 51, 0.37);
  text-align: center;
  color: #ffffff;
  max-width: 500px;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.coming-soon-logo {
  width: 200px;
  height: auto;
  margin-bottom: 2.5rem;
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.3));
}

.coming-soon-content h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
  color: #ffffff;
}

.coming-soon-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #ffffff;
  opacity: 0.9;
}

.countdown {
  font-size: 1.8rem;
  font-weight: 500;
  margin-top: 1rem;
  color: #ffffff;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
} 