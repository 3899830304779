.contact-form-wrap-two {
    max-width: 600px; /* Formun genişliğini sınırlayın */
    margin: 0 auto; /* Formu ortala */
    padding: 0 20px; /* Sağdan ve soldan boşluk bırak */
  }
  
  .contact-form-wrap-two h2 {
    text-align: center; /* Başlığı ortala */
  }
  
  .contact-form-wrap-two form {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Inputlar arası boşluk */
  }
  
  .contact-form-wrap-two input,
  .contact-form-wrap-two .checkbox-wrap {
    position: relative; /* Pozisyon ayarlaması için */
  }
  
  .contact-form-wrap-two .checkbox-wrap {
    display: flex;
    align-items: center; /* Checkbox ve metni yanyana ve ortala */
  }
  
  .contact-form-wrap-two .checkbox-wrap input[type="checkbox"] {
    /* Checkbox stilini sadeleştir */
    width: 16px; /* Boyut */
    height: 16px; /* Boyut */
    margin-right: 10px; /* Metin ile arasında boşluk */
  }
  
  .contact-form-wrap-two .btn-two {
    align-self: center; /* Kaydet butonunu ortala */
  }
  
  .contact-form-wrap-two .btn-switch {
    background: none;
    border: none;
    color: rgb(255, 255, 255); /* Mavi renk link gibi */
    padding: 0;
    margin-top: 15px; /* Üstten boşluk bırak */
    cursor: pointer; /* İmleç el şeklinde olsun */
  }
  
  .contact-form-wrap-two .btn-switch:hover {
    text-decoration: underline; /* Üzerine gelindiğinde altını çiz */
  }
  