.step-container {
    text-align: center;
    color: #000; /* Yazı rengini siyah yap */
    padding: 20px;
    margin-top: 100px; /* Üstten margin ekleyelim */
    border-radius: 8px;
    margin-bottom: 20px; /* Alttan margin ekleyelim */
    max-width: 900px;
    max-height: 700px;
    margin-left: auto;
    margin-right: auto;
}

.step-main-title {
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 40px;
    color: #000000;

}

.step-header {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.step-number {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #007bff; /* Mavi renk */
    color: #fff; /* Beyaz renk */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    margin-bottom: 20px;

}

.step-title {
    text-align: center;
    color: #000000;
    margin-bottom: 20px;
}

.step-subtitle {
    margin-bottom: 30px;
    color: #000000;

}

.step-description {
    margin-bottom: 30px;
    color: #000 !important; /* Yazı rengini siyah yap ve !important ekle */
}

.step-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.step-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    margin: 0 10px;
}

.step-buttons button:hover {
    background-color: #0056b3;
}

.step-image {
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    max-width: 100%;
    width: 100%;
    max-width: 600px;
    height: 350px;
    object-fit: cover;
    object-position: top; /* Görsellerin yukarı dayalı olmasını sağlar */
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}