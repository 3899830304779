@import url('https://api.fontshare.com/v2/css?f[]=clash-display@600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.buy-token-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(13, 12, 34, 0.85);
  backdrop-filter: blur(12px);
  z-index: 1000;
  padding: 16px;
}

.buy-token-card {
  background: linear-gradient(165deg, #1E1E2E, #2A2A3F);
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
  width: 100%;
  max-width: 720px;
  position: relative;
  animation: slideUp 0.5s ease-out;
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 1050;
  display: flex;
  flex-direction: column;
}

.step-container {
  padding: 16px;
  flex: 1;
  overflow-y: auto;
}

.main-title {
  font-family: 'Clash Display', sans-serif;
  font-size: 28px;
  font-weight: 700;
  margin: 0 0 16px 0;
  background: linear-gradient(90deg, #00F5A0, #00D9F5);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  letter-spacing: -0.02em;
  line-height: 1.2;
}

.description {
  font-size: 14px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  margin-bottom: 24px;
}

.crypto-payment {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
}

.amount-label {
  font-family: 'Clash Display', sans-serif;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 12px;
  text-align: center;
  font-weight: 600;
  letter-spacing: -0.01em;
}

.amount-input-container {
  position: relative;
  margin-bottom: 24px;
}

.amount-input {
  font-family: 'Inter', sans-serif;
  width: 100%;
  padding: 8px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  text-align: center;
  transition: all 0.3s ease;
  font-weight: 600;
  letter-spacing: -0.02em;
  height: 42px;
}

.amount-input:focus {
  outline: none;
  border-color: #00F5A0;
  box-shadow: 0 0 16px rgba(0, 245, 160, 0.15);
}

.amount-input.valid {
  border-color: #00F5A0;
}

.amount-input.invalid {
  border-color: #FF6B6B;
}

.bnb-equivalent {
  font-size: 16px;
  color: #333;
  margin-top: 10px;
}

.payment-method-buttons {
  display: flex;
  gap: 10px;
  margin: 16px 0;
}

.payment-button {
  flex: 1;
  padding: 8px;
  height: 36px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.payment-button:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  padding: 1px;
  background: linear-gradient(45deg, #00F5A0, #00D9F5);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.payment-button:hover:before {
  opacity: 1;
}

.payment-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.payment-button:not(.disabled):hover {
  color: #fff;
  transform: translateY(-1px);
}

.payment-button.active {
  background: linear-gradient(45deg, #00F5A0, #00D9F5);
  border: none;
  color: #1a1b2e;
  font-weight: 600;
  box-shadow: 0 4px 15px rgba(0, 245, 160, 0.3);
  transform: translateY(-1px);
}

.payment-button.active:before {
  display: none;
}

.payment-button.active:after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, #00F5A0, #00D9F5);
  border-radius: 11px;
  z-index: -1;
  animation: glow 1.5s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    opacity: 0.8;
    box-shadow: 0 0 5px rgba(0, 245, 160, 0.3),
                0 0 10px rgba(0, 245, 160, 0.3),
                0 0 15px rgba(0, 245, 160, 0.3);
  }
  to {
    opacity: 1;
    box-shadow: 0 0 10px rgba(0, 245, 160, 0.5),
                0 0 20px rgba(0, 245, 160, 0.5),
                0 0 30px rgba(0, 245, 160, 0.5);
  }
}

.wallet-connected {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
}

.success-message {
  color: #4CAF50;
  font-weight: bold;
}

.account-select {
  margin-bottom: 20px;
  width: 100%;
}

.account-select__control {
  background: rgba(255, 255, 255, 0.05) !important;
  border: 2px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 14px !important;
  min-height: 45px !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
}

.account-select__control:hover {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.account-select__control--is-focused {
  border-color: #00F5A0 !important;
  box-shadow: 0 0 0 1px #00F5A0 !important;
}

.account-select__menu {
  background: #2A2A3F !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 14px !important;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3) !important;
  overflow: hidden !important;
}

.account-select__option {
  color: #fff !important;
  background: transparent !important;
  padding: 12px !important;
  font-size: 14px !important;
}

.account-select__option:hover {
  background: rgba(255, 255, 255, 0.1) !important;
}

.account-select__option--is-focused {
  background: rgba(255, 255, 255, 0.05) !important;
}

.account-select__single-value {
  color: #fff !important;
  font-weight: 500 !important;
}

.next-step-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.next-step-button:hover {
  background-color: #45a049;
}

body.modal-open {
  overflow: hidden;
}

.buy-token-modal {
  max-width: 90vw !important;
  width: 800px !important;
  margin: 0.5rem auto !important;
  height: calc(100vh - 40px) !important;
  display: flex !important;
}

.buy-token-modal-dialog {
  max-width: 800px !important;
  width: 100% !important;
  margin: 0 auto !important;
  display: flex !important;
  align-items: center !important;
  height: 100% !important;
}

.buy-token-modal-content {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}

.buy-token-modal-body {
  padding: 0 !important;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}

.buy-token-overlay {
  background-color: rgba(13, 12, 34, 0.85) !important;
  backdrop-filter: blur(12px);
}

.connect-wallet-button,
.buy-tokens-button {
  width: 100%;
  padding: 10px;
  height: 42px;
  border-radius: 12px;
  border: none;
  background: linear-gradient(45deg, #00F5A0, #00D9F5);
  color: #1a1b2e;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-top: 16px;
  box-shadow: 0 4px 12px rgba(0, 245, 160, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}

.connect-wallet-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 245, 160, 0.3);
}

.connect-wallet-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  transform: none;
}

.step-buttons {
  padding: 16px;
  background: linear-gradient(165deg, #1E1E2E, #2A2A3F);
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.previous-step-button {
  padding: 8px 20px;
  height: 36px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.previous-step-button:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-1px);
}

.error-message {
  color: #FF6B6B;
  font-size: 13px;
  margin-top: 10px;
  text-align: center;
  background: rgba(255, 107, 107, 0.1);
  padding: 10px;
  border-radius: 10px;
  border: 1px solid rgba(255, 107, 107, 0.2);
}

.token-details {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  padding: 16px;
  margin-top: 16px;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.token-equivalent,
.payment-details {
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.7);
  margin: 6px 0;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-details strong {
  font-family: 'Inter', sans-serif;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.01em;
}

.total-amount {
  font-family: 'Clash Display', sans-serif;
  font-size: 16px;
  color: #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 16px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  letter-spacing: -0.01em;
}

.total-amount strong {
  font-family: 'Inter', sans-serif;
  color: #00F5A0;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.purchase-status {
  text-align: center;
  color: #00F5A0;
  margin-top: 16px;
  font-weight: 500;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .buy-token-container {
    padding: 0;
    height: 100vh;
    width: 100vw;
  }

  .buy-token-card {
    max-width: 100%;
    width: 100vw;
    height: 100vh;
    margin: 0;
    border-radius: 0;
  }

  .buy-token-modal {
    margin: 0 !important;
    padding: 0 !important;
    max-width: 100vw !important;
    width: 100vw !important;
    height: 100vh !important;
  }

  .buy-token-modal-dialog {
    margin: 0 !important;
    max-width: 100% !important;
    width: 100% !important;
    height: 100vh !important;
  }

  .buy-token-modal-content {
    border-radius: 0 !important;
    min-height: 100vh !important;
  }

  .step-container {
    padding: 16px;
    height: calc(100vh - 32px);
    overflow-y: auto;
  }

  .main-title {
    font-size: 22px;
    margin-bottom: 12px;
  }

  .close-button {
    top: 8px;
    right: 8px;
    width: 28px;
    height: 28px;
    font-size: 14px;
  }

  .connect-wallet-button,
  .buy-tokens-button {
    height: 40px;
    font-size: 13px;
    margin-top: 12px;
  }

  .step-buttons {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .previous-step-button {
    height: 34px;
    font-size: 13px;
  }
}

/* iPhone SE ve benzer küçük ekranlar için */
@media (max-width: 375px) {
  .buy-token-card {
    margin: 0;
  }

  .step-container {
    padding: 10px;
  }

  .package-button {
    padding: 14px;
    min-height: 70px;
  }

  .package-name {
    font-size: 12px;
  }

  .package-amount {
    font-size: 16px;
  }

  .amount-input {
    height: 38px;
    font-size: 16px;
  }

  .payment-button {
    height: 34px;
    font-size: 12px;
  }

  .package-bonus {
    top: 12px;
    right: 12px;
    padding: 3px 6px;
  }
}

/* iPhone 12 Pro ve benzeri orta boy ekranlar için */
@media (min-width: 376px) and (max-width: 428px) {
  .buy-token-card {
    margin: 0;
  }

  .step-container {
    padding: 12px;
  }

  .package-button {
    padding: 15px;
    min-height: 75px;
  }
}

/* Büyük ekranlı telefonlar için */
@media (min-width: 429px) and (max-width: 768px) {
  .buy-token-card {
    margin: 0 auto;
  }

  .step-container {
    padding: 14px;
  }

  .package-button {
    padding: 16px;
    min-height: 80px;
  }
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-top: 3px solid #00F5A0;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.step-indicator {
  display: flex;
  justify-content: center;
  margin: 0 0 8px 0;
  gap: 6px;
}

.step-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
}

.step-dot.active {
  background: #00F5A0;
  box-shadow: 0 0 10px rgba(0, 245, 160, 0.5);
}

.close-button {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  font-size: 16px;
  z-index: 1100;
}

.close-button:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: rotate(90deg);
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip-text {
  visibility: hidden;
  background-color: #2A2B3F;
  color: #fff;
  text-align: center;
  padding: 8px 12px;
  border-radius: 8px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.packages-container {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
  width: 100%;
}

.package-button {
  flex: 1;
  padding: 16px;
  border-radius: 12px;
  background: linear-gradient(135deg, rgba(52, 229, 157, 0.08), rgba(52, 229, 157, 0.03));
  border: 1px solid rgba(52, 229, 157, 0.15);
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
}

.package-name {
  font-family: 'Clash Display', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #34E59D;
  text-align: center;
}

.package-amount {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  text-align: center;
}

.package-bonus {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #34E59D;
  padding: 4px 8px;
  background: rgba(52, 229, 157, 0.15);
  border-radius: 6px;
  text-align: center;
}

.bonus-info {
  margin: 12px 0;
  padding: 10px;
  background: rgba(52, 229, 157, 0.1);
  border-radius: 8px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #34E59D;
  text-align: center;
  border: 1px solid rgba(52, 229, 157, 0.2);
}

.modal-lg .step-container {
  margin-top: 0;
}

.modal-dialog .step-container {
  margin-top: 0;
}