.crypto-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.crypto-title {
  text-align: center;
  margin-bottom: 20px;
  color: #f7931a;
}

.crypto-table {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 20px;
  table-layout: fixed;
  width: 100%;
}

.crypto-table thead {
  background-color: #f7931a;
}

.crypto-table th {
  color: white !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 12px 8px !important;
  vertical-align: middle;
}

.crypto-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 12px 8px !important;
  vertical-align: middle;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.coin-name {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.symbol {
  color: #666;
  font-size: 0.85em;
  margin-left: 4px;
}

.price-cell,
.change-cell,
.market-cap-cell,
.volume-cell {
  text-align: right;
  font-family: monospace;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.refresh-timer {
  text-align: center;
  color: #666;
  font-size: 0.9em;
}

.text-end {
  text-align: right !important;
}

@media (max-width: 768px) {
  .crypto-container {
    padding: 10px;
  }

  .crypto-table th,
  .crypto-table td {
    padding: 8px 4px !important;
  }

  .index-column,
  .market-cap-column,
  .volume-column {
    display: none !important;
  }

  .crypto-table th {
    font-size: 0.9em;
  }

  .crypto-table td {
    font-size: 0.85em;
  }
}