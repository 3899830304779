.chart-area {
    padding: 50px 0;
  }
  
  .chart-content {
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
  }
  
  .nav-tabs {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 20px;
  }
  
  .nav-tabs .nav-link {
    color: white;
    border: none;
    padding: 10px 20px;
  }
  
  .nav-tabs .nav-link.active {
    background-color: rgba(255, 255, 255, 0.1);
    border-bottom: 2px solid #007bff;
  }
  
  .tab-content {
    margin-top: 20px;
  }
  
  .sales-tab-content h2 {
    font-size: 24px;
    margin-bottom: 15px;
    color: white;
  }
  
  .sales-tab-content p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: rgba(255, 255, 255, 0.8);
  }
  
  .buy-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .buy-button:hover {
    background-color: #0056b3;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
  }