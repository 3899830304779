/* Mobil Menü Ana Yapısı */
.mobile-menu-wrapper {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100vh;
  background: #1a1b2e;
  z-index: 9999;
  transition: 0.4s ease;
  opacity: 0;
  visibility: hidden;
}

.mobile-menu-wrapper.active {
  right: 0;
  opacity: 1;
  visibility: visible;
}

/* Mobil Menü Butonu */
.mobile-nav-toggler {
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
}

.mobile-nav-toggler i {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggler {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* Mobil Menü Header */
.mobile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-logo {
  max-width: 140px;
}

.mobile-logo img {
  width: 100%;
  height: auto;
}

.mobile-close {
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

/* Mobil Menü İçerik */
.mobile-menu-content {
  height: calc(100vh - 81px);
  display: flex;
  flex-direction: column;
}

.mobile-nav {
  flex: 1;
  overflow-y: auto;
  padding: 20px 0;
}

.mobile-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mobile-menu li {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.3s ease;
}

.mobile-menu-wrapper.active .mobile-menu li {
  opacity: 1;
  transform: translateY(0);
}

.mobile-menu li a {
  display: block;
  padding: 15px 20px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: 0.3s ease;
}

.mobile-menu li a:hover {
  background: rgba(255, 255, 255, 0.1);
  padding-left: 25px;
}

/* Token Kaufen Butonu */
.mobile-btn {
  padding: 20px;
  background: #1a1b2e;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-btn .btn {
  display: block;
  width: 100%;
  padding: 15px;
  background: linear-gradient(to right, #f7931a, #ff7e5f);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease;
}

.mobile-btn .btn:hover {
  opacity: 0.9;
}

/* Responsive */
@media (max-width: 768px) {
  .mobile-menu-wrapper {
    width: 100%;
  }
  
  .mobile-menu li a {
    font-size: 16px;
  }
}

.offcanvas {
  background-color: #1a1b2e !important;
}

.offcanvas-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1rem;
}

.offcanvas-header .btn-close {
  color: #fff;
  opacity: 1;
  filter: brightness(0) invert(1);
}

.offcanvas-header .logo {
  max-width: 120px;
}

.offcanvas-header .logo img {
  width: 100%;
  height: auto;
}

.offcanvas-body {
  padding: 0;
}

.nav-mobile {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-mobile li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.nav-mobile li a {
  color: #fff;
  display: block;
  padding: 15px 20px;
  text-decoration: none;
  font-size: 16px;
}

.nav-mobile li a:hover {
  background: rgba(255, 255, 255, 0.1);
}

.nav-mobile-btn {
  padding: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #1a1b2e;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.nav-mobile-btn button {
  width: 100%;
  padding: 12px;
  background: linear-gradient(to right, #f7931a, #ff7e5f);
  border: none;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .offcanvas {
    width: 100% !important;
  }
} 