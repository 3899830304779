.roadmap-container {
    position: relative;
    overflow: hidden;
  }
  
  .roadmap-scroll {
    overflow-x: auto;
    padding-bottom: 20px;
    scroll-behavior: smooth; /* Yumuşak kaydırma için */
  }
    
  .roadmap-wrap-two {
    display: flex;
    min-width: 1200px; /* Yeni öğeyi barındıracak şekilde genişletildi */
  }

  .scroll-indicator {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background: rgba(0,0,0,0.5);
    color: white;
    padding: 5px 10px;
    border-radius: 20px;
  }
  
  @media (max-width: 768px) {
    .roadmap-wrap-two {
      min-width: 1000px; /* Mobil için daha geniş minimum genişlik */
    }
  }
  