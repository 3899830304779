/* Chat Container Styles */
.chat-container {
    background: rgba(255, 255, 255, 0.95);
    border-radius: 20px;
    box-shadow: 0 8px 32px rgba(31, 38, 135, 0.15);
    backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    overflow: hidden;
    transition: all 0.3s ease;
}

/* Chat Header */
.chat-header {
    background: linear-gradient(135deg, #1e90ff 0%, #00bfff 100%);
    color: white;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.chat-header-title {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
}

.chat-header-title img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.chat-controls {
    display: flex;
    gap: 10px;
}

.chat-control-button {
    background: transparent;
    border: none;
    color: white;
    padding: 5px;
    cursor: pointer;
    transition: transform 0.2s;
}

.chat-control-button:hover {
    transform: scale(1.1);
}

/* Chat Body */
.chat-body {
    padding: 20px;
    height: calc(100% - 130px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background: #f8f9fa;
}

/* Message Styles */
.message {
    max-width: 80%;
    padding: 12px 16px;
    border-radius: 15px;
    margin: 5px 0;
    position: relative;
    animation: messageAppear 0.3s ease;
}

.message.user {
    background: #007bff;
    color: white;
    align-self: flex-end;
    border-bottom-right-radius: 5px;
}

.message.bot {
    background: white;
    color: #2c3e50;
    align-self: flex-start;
    border-bottom-left-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

/* Typing Indicator */
.typing-indicator {
    display: flex;
    gap: 5px;
    padding: 10px;
    background: white;
    border-radius: 15px;
    align-self: flex-start;
    margin: 5px 0;
}

.typing-dot {
    width: 8px;
    height: 8px;
    background: #007bff;
    border-radius: 50%;
    animation: typingAnimation 1.4s infinite;
}

.typing-dot:nth-child(2) { animation-delay: 0.2s; }
.typing-dot:nth-child(3) { animation-delay: 0.4s; }

/* Chat Footer */
.chat-footer {
    padding: 15px;
    background: white;
    border-top: 1px solid #eee;
    display: flex;
    gap: 10px;
    align-items: center;
}

.chat-input-container {
    flex: 1;
    position: relative;
}

.chat-input {
    width: 100%;
    padding: 12px 45px 12px 15px;
    border: 1px solid #e0e0e0;
    border-radius: 25px;
    outline: none;
    font-size: 14px;
    transition: border-color 0.3s;
}

.chat-input:focus {
    border-color: #007bff;
}

.send-button {
    background: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.2s, background-color 0.2s;
}

.send-button:hover {
    background: #0056b3;
    transform: scale(1.05);
}

.send-button:disabled {
    background: #cccccc;
    cursor: not-allowed;
}

/* Animations */
@keyframes messageAppear {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes typingAnimation {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
}

/* Scrollbar Styling */
.chat-body::-webkit-scrollbar {
    width: 6px;
}

.chat-body::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.chat-body::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
}

.chat-body::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Word by Word Animation */
.typing-text {
    display: inline-block;
    animation: fadeIn 0.1s ease;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* Responsive Design */
@media (max-width: 768px) {
    .chat-container {
        width: 90% !important;
        height: 80vh !important;
    }
    
    .message {
        max-width: 90%;
    }
}

/* Buy Token Button */
.buy-token-button {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 10px;
    padding: 8px 16px;
    background: linear-gradient(135deg, #1e90ff 0%, #00bfff 100%);
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.buy-token-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    background: linear-gradient(135deg, #007bff 0%, #0099ff 100%);
}

.buy-token-button:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.buy-token-button svg {
    font-size: 18px;
}

/* Modal Styles */
.buy-token-modal {
    max-width: 90%;
    width: auto;
}

.buy-token-overlay {
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(4px);
}
  