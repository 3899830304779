.features-area {
    padding: 60px 0;
    background: #f8f9fa;
  }
  
  .feature-box {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .feature-icon {
    font-size: 40px;
    color: #007bff;
    margin-bottom: 15px;
  }
  
  .feature-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333;
    text-align: center;
  }
  
  .feature-description {
    font-size: 14px;
    color: #666;
    text-align: center;
    max-width: 200px; /* Bunu ekleyerek hizalamayı sağlar */
  }

  .mtt-5 {
    margin-top: '50px'; /* 5 birim üst boşluk ekler */
  }
  